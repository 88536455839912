<template>
  <div>  
    <full-screen-loader v-if="listLoading"></full-screen-loader>
    <base-header class="pb-6" type="pink">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <nav class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>

        <div class="col-5 col-lg-6 text-right" >
          <div class="row justify-content-end">
            <div class="col-12">
              <el-date-picker
                v-model="date"
                @change="dateSet"
                type="daterange"
                align="right"
                unlink-panels
                start-placeholder="Start date"
                end-placeholder="End date"
                :error="getError('filter.date_to')"
              ></el-date-picker>
            </div>
          </div>
          <div class="row justify-content-end" v-if="getError('filter.date_from')">
            <div class="col-12">
              <div class="date-error-container">{{getError('filter.date_from')}}</div>
            </div>
          </div>
          <div class="row justify-content-end" v-if="getError('filter.date_to')">
            <div class="col-12 justify-content-end">
              <div class="date-error-container">{{getError('filter.date_to')}}</div>
            </div>
          </div>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6 position-relative">
      <totals-view></totals-view>
      <div class="row">
        <div class="col-12 w-100">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <h5 class="h3 mb-0 text-light">{{getTrans('messages.traffic')}}</h5>
                </div>
              </div>
            </div>
            <div class="card-body text-light">
              <multi-line-chart :chartData="topChartData"></multi-line-chart>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card mb-2" :noBody="true">
            <div class="card-header">
              <div class="row">
                <div class="col-6 my-auto">
                  <h5 class="h2 mb-0">{{getTrans('messages.statistics')}}</h5>
                </div>
                <div class="col-6 text-right">
                  <el-popover trigger="hover" placement="left" :title="getTrans('messages.copy')" width="200px" :content="getTrans('messages.copy_link_details')">
                    <template #reference>
                      <base-button @click="copyLink" type="primary">
                        <i class="fa fa-clipboard"></i>
                      </base-button>
                    </template>
                  </el-popover>
                </div>
              </div>
            </div>
            <stats-filters
              :dimensions="getDimensions"
              :filters="activeFilters"
              :profiles="getProfiles"
              @updated="filtersUpdated"
              @columnBlacklistUpdated="columnBlacklistUpdated"
              :columns="columns"
              :fullColumns="fullColumns"
            ></stats-filters>
            <stats-table
              :data="getUserStats"
              :filters="activeFilters"
              @filtersUpdated="filtersUpdated"
              :dimensions="getDimensions"
              :blacklistColumns="columnBlacklist"
              @columnsUpdated="columnsUpdated"
              @fullColumnsUpdated="fullColumnsUpdated"
              :loading="listLoading"
            ></stats-table>
          </div>
          <div class="col">
          <base-pagination
            align="center"
            :page-count="getStatsPageCount"
            :value="getCurrentStatsPageNumber"
            @input="selectPage"
          ></base-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  ElDatePicker
} from "element-plus";
import { mapGetters } from "vuex";
import moment from "moment";

import Errors from "@/mixins/Errors";

import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";
import StatsTable from "./components/StatsTable";
import StatsFilters from "./components/StatsFilters";
import MultiLineChart from "./components/MultiScaleLinesChart.vue";
import TotalsView from "./components/TotalsView.vue"

import StatsService from "@/service/stats/stats.service";

export default {
  components: {
    RouteBreadCrumb,
    MultiLineChart,
    TotalsView,
    StatsTable,
    StatsFilters,
    ElDatePicker
  },
  mixins: [
    Errors, 
  ],
  data() {
    return {
      columnBlacklist: ["redirects","roi","ctr","e_cpm","conv_rate"],
      columns: [],
      fullColumns: [],
      activeFilters: { groupBy: "date" },
      fallbackFilters: { groupBy: "date" },
      chartView: 'bar',
      selectedGraphView: 'redirects',
      date: [
        moment()
          .subtract(1, "months")
          .add(2, "days")
          .toDate(),
        moment()
          .add(1, "day")
          .toDate()
      ],
      bigLineChart: {
        allData: [
          [0, 20, 10, 30, 15, 40, 20, 60, 60],
          [0, 20, 5, 25, 10, 30, 15, 40, 40],
        ],
        activeIndex: 0,
      },
      topChartStatsWhiteList: [
        {
          label: "impressions",
          translation: "messages.impressions",
          color: "#20a8d8"
        },
        { label: "clicks", translation: "messages.clicks", color: "#f8cb00" },
        { label: "sales", translation: "messages.sales", color: "#4dbd74" },
        {
          label: "registrations",
          translation: "messages.registrations",
          color: "rgb(109, 60, 224)"
        },
        { label: "rebills", translation: "messages.rebills", color: "#f86c6b" },
        {
          label: "redirects",
          translation: "messages.redirects",
          color: "#63c2de"
        },
        {
          label: "earnings_sum",
          translation: "messages.earnings",
          color: "black"
        },
        {
          label: "costs",
          translation: "messages.costs",
          color: "red"
        },
        { label: "roi", translation: "messages.roi", color: "#afd5aa" },
        { label: "ctr", translation: "messages.ctr", color: "#83c6dd" },
        { label: "e_cpm", translation: "messages.e_cpm", color: "#f7ddcd" },
        {
          label: "conv_rate",
          translation: "messages.conv_rate",
          color: "green"
        },
        { label: "opens", translation: "messages.opens", color: "#4dbd74" }
      ],
      fallbackGraphData:[],
      fallbackTotals: {},
    }
  },
  watch: {
    activeFilters: {
      deep: true,
      handler() {
        if (this.activeFilters?.date_from) {
          this.date[0] = moment(this.activeFilters.date_from, "YYYY-MM-DD")
          this.date[1] = moment(this.activeFilters.date_to, "YYYY-MM-DD")
        }
        this.getData(true);
      }
    },
  },
  computed: {
    ...mapGetters("Profile", {getProfiles: 'getList'}),
    ...mapGetters("Stats", {
      getTotals: 'getTotals', 
      errorsByName: "getErrorsByName", 
      getUserStats: "getUserStats",
      getDatedStats: "getDatedStats",
      getStatsPageCount: "getStatsPageCount",
      getCurrentStatsPageNumber: "getCurrentStatsPageNumber",
      getDimensions: "getDimensions",
      listLoading: "listLoading",
    }),
    errors() {
      return this.errorsByName('stats')
    },
    topChartData() {
      if (this.getDatedStats) {
        return {
          labels: this.getLabelsFromStats(this.getDatedStats),
          datasets: !this.getDatedStats.length ? [] : Object.keys(
              this.getDatedStats[0]
            ).filter(
              k=>{
                return this.topChartStatsWhiteList.findIndex(
                  s=>{
                    return s.label==k}
              ) >=0 }
            ).map((k) => {
            const setting = this.topChartStatsWhiteList.find(s => k == s.label);
            return {
              label: this.getTrans("messages." + k),
              borderColor: setting?.color,
              backgroundColor: setting?.color,
              data: this.getDatedStats.map(s => {
                return setting?.format ? setting.format(s[k]) : s[k];
              })
            }
          }),
        }
      }
      return [];
    }
  },
  methods: {
    dateSet() {
      Object.assign(this.activeFilters, {
        date_from: moment(this.date[0]).format("YYYY-MM-DD"),
        date_to: moment(this.date[1])
          .add(1, "days")
          .format("YYYY-MM-DD")
      });
    },
    columnsUpdated(list) {
      this.columns = list;
    },
    columnBlacklistUpdated(list) {
      this.columnBlacklist = list;
    },
    fullColumnsUpdated(list) {
      this.fullColumns = list;
    },
    selectPage(val) {
      this.$set(this.activeFilters, 'page', val);
      this.filtersUpdated(this.activeFilters);
      this.getData();
    },

    updateStats() {
      let filters = Object.assign({},this.activeFilters);
      this.$store.dispatch("Stats/get", StatsService.getParamsFromFilters(filters));
      if (filters.groupBy != 'date' && (!filters.sortColumn || filters.sortColumn != 'date') && (!filters.sortDirection || filters.sortDirection != 'asc')) {
        this.$store.dispatch("Stats/getGraphData", StatsService.getParamsFromFiltersForGraph(filters));
      } else {
        this.$store.commit('Stats/setData', {name: 'datedStats', data: [], pagination: null, loading: false});

      }
      this.$store.dispatch("Stats/getTotals", StatsService.getParamsFromFilters(filters));
    },

    filtersUpdated(newFilters) {
      this.activeFilters = newFilters;
    },

    getData() {
      this.updateStats();
    },

    getLabelsFromStats(stats) {
      try{
        return stats.map(s => {
          return moment(s.event_date).format("L");
        });
      } catch (e) {
        return []
      }
    },

    getDatesFromPicker() {
      return {
        date_from: moment(this.date[0]).format("YYYY-MM-DD"),
        date_to: moment(this.date[1])
          .add(1, "days")
          .format("YYYY-MM-DD")
      };
    },
    copyLink() {
      let resolved = this.$router.resolve({path: this.$route.path, query: {filter: JSON.stringify(this.activeFilters)} });
      try {      
        navigator.clipboard.writeText(resolved.href);
        this.$toast.success(this.getTrans("messages.link_copied"));
      } catch (e) {
        this.$toast.error(this.getTrans('messages.cant_copy'))
      }
    }
  },
  mounted() {
    var s = this.$route.query.filter
    if (s) {
      this.activeFilters = JSON.parse(s);
    }
    if (!this.activeFilters.date_from) {
      this.dateSet();
    }
    this.getData();
    this.$store.dispatch("Profile/getAll");
    this.$store.dispatch("Dictionary/get");
    this.$store.dispatch("Platform/get");
    this.$store.dispatch("Network/GET");
  },
}
</script>