<template>
  <el-table
    v-if="columns"
    class="table-responsive table-flush"
    header-row-class-name="thead-light"
    :data="data"
    :default-sort="{prop: 'date', order: 'descending'}"
    style="width: 100%"
    @sort-change="setSorting"
  >
    <el-table-column
      v-for="(col, colIndex) in columns"
      :key="col"
      :prop="col"
      :label="getTrans('messages.'+col)"
      :index="colIndex"
      :sortable="'custom'"
    >
      <template #default="scope">
        <div @click="setScope(scope.row)" v-if="colIndex==0">
          <el-tooltip :content="getTrans('messages.filter_on_this') + ' ' + getTrans('messages.'+activeFilters.groupBy)" placement="top">
            <span :class="'clickable-row-'+colIndex">{{scope.row[col] ? formatValue(scope.row[col]) : getTrans(groupedDim.table_null_trans)}}</span>
          </el-tooltip>
        </div>
        <span v-else :class="'clickable-row-'+colIndex">{{formatValue(scope.row[col])}}</span>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
import { ElTable, ElTableColumn } from "element-plus";

export default {
  name: "stats-table",
  emits: ["columnsUpdated", "fullColumnsUpdated", "filtersUpdated"],
  props: {
    data: Array,
    filters: Object,
    dimensions: Array,
    blacklistColumns: Array,
    loading: Boolean,
  },
  components: {
    [ElTable.name]: ElTable,
    [ElTableColumn.name]: ElTableColumn,
  },
  data() {
    return {
      tableData: [],
      activeFilters: { groupBy: "date", custom: [] }
    };
  },
  computed: {
    groupedDim() {
      try {
        let g = this.activeFilters.groupBy
        var result = this.dimensions.find(
          d => d.param_name == g
        );
        return result
      } catch (e) {
        return {};
      }
    },

    columns() {
      if (this.data && this.data.length >= 0 && this.data[0]) {
        //list without blacklisted items
        var keys = Object.keys(this.data[0]).filter(k => {
          //checking blacklist and dimensions if col should be displayed
          return this.blacklistColumns.indexOf(k) < 0 && (this.dimensions.find(d=> d.table_name==k && d.table_display_name) ? false : true);
        });
        //set groupby columns first
        if (this.activeFilters && this.activeFilters.groupBy) {
          var groupedKeys = [];
          var newKeys = keys.filter(k => {
            var grouped =
              k.includes(this.groupedDim.table_name) ||

              (this.groupedDim.table_display_name && k.includes(this.groupedDim.table_display_name)) ||

              k.includes(this.activeFilters.groupBy);


              this.groupedDim.table_display_name
            if (grouped) {
              groupedKeys.push(k);
            }
            return !grouped;
          });
          if (keys == newKeys) {
            return keys;
          } else {
            groupedKeys.forEach(g => {
              newKeys.unshift(g);
            });
            return newKeys;
          }
        }
        return keys;
      }
      return [];
    },
    fullColumns() {
      if (this.data && this.data.length >= 0 && this.data[0]) {
        return Object.keys(this.data[0]);
      } else {
        return [];
      }
    }
  },
  watch: {
    data(newValue) {
      this.tableData = newValue.slice(0).sort((a, b) => {
        if (a[this.columns[0]] < b[this.columns[0]]) return 1;
        if (a[this.columns[0]] > b[this.columns[0]]) return -1;
        return 0;
      });
    },
    filters: {
      deep: true,
      handler() {
        if(this.filters){
          this.activeFilters = this.filters;
        }
      },
      immediate: false,
    },
    'filters.groupBy': {
      handler() {
        this.activeFilters.groupBy = this.filters.groupBy;
      },
      deep: true,
      immediate: false,
    },
    columns: {
      deep: true,
      handler() {
        this.$emit("columnsUpdated", this.columns);
      }
    },
    fullColumns: {
      deep: true,
      handler() {
        this.$emit("fullColumnsUpdated", this.fullColumns);
      }
    }
  },
  methods: {
    setSorting({order, prop}) {
      if (order) {
        this.activeFilters.sortColumn = prop;
        this.activeFilters.sortDirection = order[0] == 'a' ? 'asc' : 'desc';
        this.filtersUpdated();
      }
      
    },
    formatValue(value) {
      if (isNaN(value)) {
        return value;
      } else {
        return Math.floor(value);
      }
    },
    setScope(row) {
      let value = null;
      let display = ''
      let dimIndex = this.dimensions.indexOf(
        this.dimensions.find(d => d.param_name == this.groupedDim.param_name)
      );
      
      value = row[this.groupedDim.table_name];
      display = this.groupedDim.table_display_name ? row[this.groupedDim.table_display_name] : value;
      this.activeFilters.custom.push({ value: value, dimIndex: dimIndex, display: display });

      this.filtersUpdated();
    },
    filtersUpdated() {
      this.$emit("filtersUpdated", this.activeFilters);
    }
  },
  mounted() {
    if (this.filters) {
      this.activeFilters = this.filters;
    } else {
      this.filtersUpdated();
    }
  }
};
</script>
<style scoped>
.clickable-row-0 {
  color: darkblue;
  cursor: pointer;
}
</style>