<template>
  <div class="row" v-if="Object.keys(getTotals).length !== 0">
    <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.popups')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.redirects)"
            icon="ni ni-send"
          ></stats-card>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.ad_views')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.impressions)"
            icon="ni ni-badge"
          ></stats-card>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.opens')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.opens)"
            icon="ni ni-circle-08"
          ></stats-card>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.clicks')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.clicks)"
            icon="ni ni-active-40"
          ></stats-card>
        </div>
      </div>
    </div>
    <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
      <div class="row">
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.registrations')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.registrations)"
            icon="ni ni-archive-2"
          ></stats-card>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.sales')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.sales)"
            icon="ni ni-shop"
          ></stats-card>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.rebills')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.rebills)"
            icon="fa fa-recycle"
          ></stats-card>
        </div>
        <div class="col-xs-6 col-md-6 col-lg-3">
          <stats-card
            :title="getTrans('messages.earnings')"
            type="gradient-red"
            :sub-title="formatTotal(getTotals.earnings)"
            icon="ni ni-credit-card"
          ></stats-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import StatsCard from "@/components/Cards/StatsCard";

export default {
  components: {
    StatsCard,
  },
  computed: {
    ...mapGetters("Stats", ["getTotals"]),
  },
  methods: {
    formatTotal(value) {
      if (value) return parseInt(value).toString();
      return "-";
    },
  },
};
</script>
