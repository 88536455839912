<template>
  <vue3-chart-js ref="chartRef" v-if="chart" :height="350" v-bind="{ ...chart }" />
</template>

<script>
import Vue3ChartJs from "@j-t-mcc/vue3-chartjs";
import PluginDatalabels from 'chartjs-plugin-datalabels'

Vue3ChartJs.registerGlobalPlugins([PluginDatalabels])

export default {
  name: "multi-line-chart",
  components: {
    Vue3ChartJs,
  },
  props: {
    chartData: {
      type: Object,
      default: ()=>{return{labels:[],datasets:[]}}
    },
    defaultNumberShown: {
      type: Number,
      default:()=> {
        return 1;
      }
    }
  },
  data() {
    return {
      chart: null,
      compDatasets: [],
    }
  },
  watch: {
    chartData: {
      deep:true,
      handler() {
        if (this.chartData?.datasets) {
          this.updateChartData()
        }
      },
    },
  },
  methods: {
    updateChartData() {
      let newScales = {};
      for (let index = 0; index < this.chartData.datasets.length; index++) {
        const element = this.chartData.datasets[index];
        element.yAxisID = 'y'+index;
        element.xAxisID = 'x';
        element.datalabels = {
          align: 'start',
          anchor: 'start',
          autoSkip: true,
        };
        if (index>=this.defaultNumberShown) {
          element.hidden = true
        }
        newScales['y'+index] = {
          min:0,
          display:false,
          type: 'linear',
          grid: {
            drawOnChartArea: false,
          },
        }
      }
      this.chart.data.labels = this.chartData.labels
      this.chart.data.datasets = this.chartData.datasets;
      this.chart.options.scales = newScales;
      try{
        this.$refs.chartRef.update(250);
        // eslint-disable-next-line
      } catch {
      }
    },
  },
  mounted() {
    this.chart = {
      id: 'linechart',
      type: 'line',
      data: {
        labels: [],
        datasets: []
      },
      options: {
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
            labels: {
              fontColor: '#333',
              usePointStyle:true
            }
          },
          datalabels: {
            color: function(context) {
              return context.dataset.backgroundColor;
            },
            font: {
              weight: 'bold'
            },
            formatter: Math.round,
          }
        },
        stacked: false,
        elements: {
          line: {
            tension:0.4
          }
        },
        interaction: {
          mode: 'index',
          intersect: false,
        },
        responsive: true,
        maintainAspectRatio: false,
        
        tooltips: {
          enabled: true,
          mode: "index",
          intersect: false
        },
        scales: {
          x: {
            gridLines: {
              drawBorder: false,
              fontColor: "#FFFFFF",
              zeroLineColor: "transparent"
            },
            ticks: {
              padding: 10,
              fontColor: "#FFFFFF",
              fontFamily: "Open Sans",
              autoSkip: true,
            },
            title: {
              color: "#FFFFFF",

            }
          }
        },
        layout: {
          padding: 0
        }
      },
    }
  },
}
</script>
