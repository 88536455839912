<template>
  <el-select v-model="value" @change="updateValue(''+value)">
    <el-option
      v-for="item in countries"
      :key="item.id"
      :label="item.name"
      :value="item.id">
      <country-flag :logo="item.logo" ></country-flag>
      <span>{{ item.name }}</span>
    </el-option>
  </el-select>
</template>

<script>
import {ElSelect, ElOption} from 'element-plus'
import CountryFlag from "@/components/Diva/Tags/CountryFlag.vue";
  export default {
    name: 'country-select',
    emits: ['input'],
    props: {
      countries: {
          type: Array
      }
    },
    data() {
        return {
            value:"",
        }
    },
    components: {
        [ElSelect.name]: ElSelect,
        [ElOption.name]: ElOption,
        CountryFlag,
    },
    methods: {
      updateValue: function (value) {
        this.$emit('input', value)
      }
    }
  }
</script>