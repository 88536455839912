<template>
  <ul class="list-group list-group-flush">
    <li class="list-group-item" v-if="activeFilters">
      <badge
        type="primary"
        class="filter-badge mr-2"
        size="lg"
      >
        <span>{{activeFilters.date_from}}</span>
        <i class="fas fa-arrow-right px-2"></i>
        <span>{{activeFilters.date_to}}</span>
      </badge>
      <badge
        v-for="(filt, index) in activeFilters.custom"
        type="primary"
        :key="index"
        class="filter-badge mr-2"
        size="lg"
      >
        <span v-if="filt && filt.dimIndex">{{dimensions[filt.dimIndex].display_name+": "}}</span>
        <span v-if="filt && filt.display">{{filt.display}}</span>
        <span v-else-if="filt && filt.value && dimensions[filt.dimIndex].displayValue">{{dimensions[filt.dimIndex].displayValue(filt.value)}}</span>
        <span v-else-if="filt && filt.value">{{filt.value}}</span>
        <div class="filter-badge-icon" @click="removeFilter(index)">
          <i class="fas fa-times pl-2"></i>
        </div>
      </badge>
    </li>
    <li class="list-group-item">
      <div class="col-12">
        <el-form :inline="true" :model="formInline" class="demo-form-inline mb-0 pl-4 py-2">
          <el-form-item label="Group By" class="mb-0 font-weight-600">
            <el-select v-model="groupBy" collapse-tags>
              <el-option
                v-for="item in groupByDimensions"
                :key="item.param_name"
                :label="getTrans('messages.'+item.display_name)"
                :value="item.param_name"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-popover v-model:visible="visible" placement="bottom" trigger="click" width="auto" popper-class="p-0">
            <div class="add-filter-container">
              <el-menu default-active="2" class="el-menu-vertical-demo" :collapse="false">
                <el-menu-item>
                  <el-date-picker
                    v-model="date"
                    @change="setNewDate"
                    type="daterange"
                    align="right"
                    unlink-panels
                    start-placeholder="Start date"
                    end-placeholder="End date"
                  ></el-date-picker>
                </el-menu-item>
                <el-submenu
                  v-for="dim in filterDimensions"
                  :key="dim.display_name"
                  :index="dim.param_name"
                >
                  <template #title>
                    <span>{{getTrans('messages.'+dim.display_name)}}</span>
                  </template>
                  <el-menu-item-group>
                    <el-menu-item :index="dim.param_name" height="500px">
                      <!-- The different filter inputs -->
                      <div class="filter-input-container">
                        <el-input v-if="dim.type=='text'" v-model="customFilters[dim.param_name]"></el-input>
                        <el-input-number v-if="dim.type=='number'" v-model="customFilters[dim.param_name]"></el-input-number>
                        <el-date-picker
                          v-if="dim.type=='date'"
                          v-model="customFilters[dim.param_name]"
                          type="daterange"
                          align="right"
                          unlink-panels
                          start-placeholder="Start date"
                          end-placeholder="End date"
                        ></el-date-picker>
                        <country-select
                          v-if="dim.type=='country' && getCountries"
                          :countries="getCountries"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                        ></country-select>
                        <!-- Network select -->
                        <el-select
                          v-if="dim.type=='network'"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                          filterable
                        >
                          <el-option v-for="(item) in getNetworks" :key="item.id"  :value="item.id" :label="item.title"></el-option>
                        </el-select>
                        <!-- Camsite select -->
                        <el-select
                          v-if="dim.type=='camsite'"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                          filterable
                        >
                          <el-option v-for="item in getPlatforms" :key="item.id"  :value="item.id" :label="item.title"></el-option>
                        </el-select>
                        <!-- Brand select -->
                        <!-- 4000 brands slows down el-select unmount -->
                        <select
                          v-if="dim.type=='brands'"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                          filterable
                        >
                          <option v-for="item in getDeviceBrands" :key="item.id"  :value="item.id" :label="item.name"></option>
                        </select>
                        <!-- Device types select -->
                        <el-select
                          v-if="dim.type=='devices'"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                          filterable
                        >
                          <el-option v-for="item in getDeviceTypes" :key="item.id"  :value="item.id" :label="item.name"></el-option>
                        </el-select>
                        <!-- Browser select -->
                        <el-select
                          v-if="dim.type=='browser'"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                          filterable
                        >
                          <el-option v-for="item in getBrowsers" :key="item.id"  :value="item.id" :label="item.name"></el-option>
                        </el-select>
                        <!-- OS select -->
                        <el-select
                          v-if="dim.type=='os'"
                          v-model="customFilters[dim.param_name]"
                          @change="itemSelected(dim.param_name)"
                          filterable
                        >
                          <el-option v-for="os in getOs" :key="os?.id ? os?.id : 0"  :value="os?.id ? os.id : 0" :label="os.name ? os.name : 0"></el-option>
                        </el-select>
                      </div>
                      <base-button v-if="dim.type=='text' || dim.type=='number'" type="primary" @click="createFilter(customFilters[dim.param_name], dim.param_name)">{{getTrans('messages.add_filter')}}</base-button>
                    </el-menu-item>
                  </el-menu-item-group>
                </el-submenu>
              </el-menu>
            </div>
            <template #reference>
              <base-button type="primary" @click="visible = true">{{getTrans('messages.add_filter')}}</base-button>
            </template>
          </el-popover>
          <el-form-item :label="getTrans('messages.show_columns')" class="mb-0 font-weight-600 float-right">
            <el-select
              multiple
              collapse-tags
              v-model="columnBlacklist"
              tag-type=""
            >
              <option
                v-for="col in fullColumns"
                :value="col"
                :key="col"
              >{{getTrans("messages."+col)}}</option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </li>
  </ul>
</template>
<script>
import {
  ElForm,
  ElFormItem,
  ElSelect,
  ElOption,
  ElInput,
  ElInputNumber,
  ElMenu,
  ElMenuItem,
  ElMenuItemGroup,
  ElSubmenu,
  ElDatePicker
} from "element-plus";
import CountrySelect from "@/components/Diva/Inputs/CountrySelect";
import { mapGetters } from "vuex";
import moment from "moment";

/**Note: blacklist was turned into whitelist, to change back modify columnblacklist watcher emits */
export default {
  name: "stats-filters",
  emits: ["columnBlacklistUpdated", "updated"],
  props: {
    dimensions: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object,
      default: () => {
        return { groupBy: "date", custom: [] };
      }
    },
    profiles: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    fullColumns: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  components: {
    ElForm,
    ElFormItem,
    ElDatePicker,
    ElSelect,
    ElOption,
    ElInput,
    ElInputNumber,
    ElMenu,
    ElMenuItem,
    ElMenuItemGroup,
    ElSubmenu,
    CountrySelect,
  },
  data() {
    return {
      formInline: {},
      activeFilters: {
        groupBy: "date",
        custom: []
      },
      groupBy: "date",
      customFilters: {},
      date: [],
      visible: false,
      defaultDate: [
        moment()
          .subtract(1, "months")
          .add(2, "days")
          .toDate(),
        moment()
          .add(1, "day")
          .toDate()
      ],
      //default whitelist
      columnBlacklist: ["redirects", "opens", "event_date", "earnings_sum", "costs", "rebills", "registrations", "sales", "clicks", "impressions"],
    };
  },
  computed: {
    ...mapGetters("Os", ["getOs"]),
    ...mapGetters("Browser", ["getBrowsers"]),
    ...mapGetters("Platform", ["getPlatforms"]),
    ...mapGetters("DeviceBrand", ["getDeviceBrands"]),
    ...mapGetters("DeviceType", ["getDeviceTypes", "getDeviceTypeById"]),
    ...mapGetters("Network", ["getNetworks"]),


    ...mapGetters("Country", ["getCountries"]),
    ...mapGetters("Stats", {
      getDimensions: 'getDimensions',
      getDimensionIndexByParamName: 'getDimensionIndexByParamName',
    }),
    groupedDim() {
      try {
        return this.dimensions.find(
          d => d.param_name == this.activeFilters.groupBy
        );
      } catch (e) {
        return this.dimensions[0];
      }
    },
    filterDimensions() {
      return this.dimensions.filter(d => !d.filterDisabled);
    },
    groupByDimensions() {
      return this.dimensions.filter(d => !d.groupByDisabled);
    },
    fullColumnList() {
      let tot = this.columns.slice();
      this.columnBlacklist.forEach(c => {
        tot.indexOf(c) < 0 ? tot.push(c) : null;
      });
      return tot;
    }
  },
  watch: {
    filters: {
      handler() {
        if (this.filters) {
          this.activeFilters = this.filters;
          if (!this.activeFilters.custom) {
            this.activeFilters.custom = [];
          }
          this.groupBy = this.activeFilters.groupBy;
          if (this.filters.date_from) {
            this.date[0] = moment(this.filters.date_from)
          }
          if (this.filters.date_to) {
            this.date[1] = moment(this.filters.date_to)
          }
        }
        this.updated();
      },
      deep: true,
      immediate: false,
    },
    groupBy() {
      this.activeFilters.groupBy = this.groupBy;
      this.updated();
    },
    columnBlacklist:{
      handler() {
        if (this.columnBlacklist.length != 0) {
          this.$emit("columnBlacklistUpdated", this.fullColumns.filter(c => {
            return this.columnBlacklist.indexOf(c) < 0
          }));
        } else {
          this.$emit("columnBlacklistUpdated", [])
        }
      },
      deep: true,
      immediate: false,
    }
  },
  methods: {
    formatter(row) {
      return row.address;
    },
    updated() {
      
      this.$emit("updated", this.activeFilters);
    },
    createFilter(value, dim, display) {
      let dimIndex = this.getDimensionIndexByParamName(dim);
      const dimension = this.getDimensions[dimIndex];
      if (display) {
        this.activeFilters.custom.push({
          value: value,
          dimIndex: dimIndex,
          display: display,
        });
      } else if (dimension.displayValue) {
        this.activeFilters.custom.push({
          value: value,
          dimIndex: dimIndex,
          display: dimension.displayValue(value),
        });
      } else {
        this.activeFilters.custom.push({
          value: value,
          dimIndex: dimIndex,
        });
      }
      this.customFilters = {};
      this.updated();
      this.visible = false;
    },
    removeFilter(removeIndex) {
      this.activeFilters.custom.splice(removeIndex, 1);
      this.updated();
    },
    setNewDate(dates){
      let newDates = {
        date_from: moment(dates[0]).format("YYYY-MM-DD"),
        date_to: moment(dates[1])
          .add(1, "days")
          .format("YYYY-MM-DD")
      }
      Object.assign(this.activeFilters, newDates);
      this.updated();
    },
    itemSelected(dim, value) {
      if (value) {
        this.createFilter(value, dim);
      } else {
        this.createFilter(this.customFilters[dim], dim);
      }
    }
  },
};
</script>
<style scoped>
.filter-badge-icon {
  display: contents;
  color: red;
}
</style>
